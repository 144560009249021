import { Routes } from "@angular/router";

import { StaticPageComponent } from "../static-page.component";

export const staticPageRoutes: Routes = [
  {
    path: "",
    component: StaticPageComponent,
  },
];
