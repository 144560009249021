import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ResetPasswordPageComponent } from "../reset-password.component";
import { ResetPasswordPageRoutingModule } from "../routes/reset-password-page-routing.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    ResetPasswordPageRoutingModule,
  ],
  declarations: [
    ResetPasswordPageComponent,
  ],
})

export class SharedResetPasswordPageModule {}
