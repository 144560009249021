import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { staticPageRoutes } from "./static-page.routes";

@NgModule({
  imports: [
    RouterModule.forChild(staticPageRoutes),
  ],
  exports: [RouterModule],
})
export class StaticPageRoutingModule { }
