import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { LoginDialogComponent } from "./login-dialog.component";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { reCaptchaProviders } from "booking-app-v2/shared/configs";

@NgModule({
  declarations: [ LoginDialogComponent ],
  imports: [
    SharedModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [ LoginDialogComponent ],
  providers: [
    DialogService,
    ...reCaptchaProviders,
  ],
  entryComponents: [ LoginDialogComponent ],
})
export class LoginDialogModule {}
