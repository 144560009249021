import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { PrismicService } from "booking-app-v2/shared/services/prismic.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

import { PageData, PressReleaseData, PrismicSettings } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class StaticPageService {

  static readonly LANGUAGE_MAPPING = {
    "zh-CN": "zh",
    "zh-TW": "hk",
    "pt-BR": "pt",
    "tw": "tw",
    "ms": "en",
    "nl": "en",
    "ar": "ar",
    "da": "en",
    "lv": "en",
    "no": "en",
    "fi": "en",
    "kr": "en",
    "my": "en",
    "sv": "en",
    "ru": "en",
  };

  prismicSettings: PrismicSettings;

  constructor(
    private apiDataService: ApiDataService,
    private prismicService: PrismicService,
    private appSettingsService: AppSettingsService,
  ) {
    this.prismicSettings = this.appSettingsService.prismicSettings;
  }

  getPage(page: string, locale: string): Observable<any> {
    if (this.hasPrismicImplementation()) {
      this.prismicService.initPrismicConnection();
      return this.prismicService.getPage(locale);
    } else {
      const mappedLocale: string = StaticPageService.LANGUAGE_MAPPING[locale] || locale;
      const url: string = this.pageUrl(page, mappedLocale);

      return this.apiDataService.jsonP(url)
        .pipe(map((response: HttpResponse<PageData>) => response));
    }
  }

  getPressReleases(): Observable<any> {
    const url: string = "https://kaligopress.herokuapp.com/?json=get_recent_posts";

    return this.apiDataService.jsonP(url)
      .pipe(map((response: HttpResponse<PressReleaseData>) => response));
  }

  hasPrismicImplementation(): boolean {
    return (this.prismicSettings.enabled && !!this.prismicService.getPath());
  }

  private pageUrl(page: string, locale: string): string {
    return `pages/${page}?locale=${locale}`;
  }
}
