import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { StaticPageService } from "booking-app-v2/shared/services/static-page.service";
import { GlobalDataEnum, Page, PageData } from "booking-app-v2/shared/types";
import { Locale } from "booking-app-v2/shared/models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "static-page",
  template: `
    <div *ngIf="page"
         id="skip-to-content"
         class="mainContainer policy-page row max-size">
      <div [innerHTML]="page.content" [class]="pageElementClassname + ' col-md-9 ltr ' + prismicClassName"></div>
    </div>
  `,
})
export class StaticPageComponent implements OnInit {
  page: Page;
  pageName: string;
  pageElementClassname: string;
  langCode: string;
  prismicClassName: string;

  constructor(
    private globalData: GlobalData,
    private localeService: LocaleService,
    private location: Location,
    private staticPageService: StaticPageService,
  ) {
    this.langCode = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE)?.langCode;
    this.prismicClassName = this.staticPageService.hasPrismicImplementation() ? "prismic-content" : "";
  }

  ngOnInit() {
    // TODO: Check if it's still needed to do below things here when migration finishes
    // if (this.kaligoConfig.ignoreAngularRoute) {
    //   return;
    // }
    this.initPageName();
    this.initPageElementClassname();
    this.loadPage(this.pageName, this.langCode);
    this.listenLangChange();
  }

  private initPageName(): void {
    const url: string = this.location.path();
    this.pageName = url.replace(/\/(company|press|terms)\//, "");
    if (this.pageName === "terms_condition") {
      this.pageName = "terms";
    }
  }

  private initPageElementClassname(): void {
    if (this.pageName.includes("faq")) {
      this.pageElementClassname = "faqs-page";
    } else if (this.pageName.includes("privacy") || this.pageName.includes("terms")) {
      this.pageElementClassname = "terms-page";
    } else {
      this.pageElementClassname = this.pageName.replace("_", "-");
      this.pageElementClassname += "-page";
    }
  }

  private listenLangChange(): void {
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe((newLocale: Locale) => {
        this.loadPage(this.pageName, newLocale.langCode);
      });
  }

  private loadPage(pageName: string, localeParam: string): void {
    this.staticPageService.getPage(pageName, localeParam)
      .subscribe((pageData: PageData) => {
        pageData.error ?
          this.loadPage(pageName, "en") :
          this.page = pageData.page;
      });
  }
}
