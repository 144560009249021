import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "booking-app-v2/shared/services/user.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { ApiDataError, ResetPassword } from "booking-app-v2/shared/types";
import { passwordMismatchValidator, weakPasswordValidator } from "booking-app-v2/shared/validators";

@Component({
  templateUrl: `/html/account/reset_password_v2`,
})

export class ResetPasswordPageComponent implements OnInit {
  passwordForm: FormGroup;
  resetPasswordToken: string;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private windowRefService: WindowRefService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.resetPasswordToken = this.activatedRoute.snapshot.queryParams.reset_password_token;
    this.initPasswordForm();
  }

  submitPasswordForm(): void {
    if (this.passwordForm.invalid) {
      return;
    }
    const passwordFormControls: { [key: string]: AbstractControl } = this.passwordForm.controls;
    const resetPasswordParams: ResetPassword = {
      reset_password_token: this.resetPasswordToken,
      password: passwordFormControls.newPassword.value,
      password_confirmation: passwordFormControls.confirmNewPassword.value,
    };
    this.userService.resetPassword(resetPasswordParams).subscribe({
      next: () => {
        this.displayAlertMessage(true, "Your password has been updated");
        this.windowRefService.nativeWindow.location.href = "/";
      },
      error: (err: ApiDataError) => {
        this.displayAlertMessage(false, Object.values(err.errors[0])[0][0]);
      },
    });
  }

  private initPasswordForm(): void {
    this.passwordForm = new FormGroup({
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        weakPasswordValidator(/^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9])(?=.*[a-z]).{8,}$/),
      ]),
      confirmNewPassword: new FormControl("", Validators.required),
    }, { validators: passwordMismatchValidator });
  }

  private displayAlertMessage(success: boolean, message: string): void {
    const alertMessage: string = this.translateService.instant(message);

    this.snackBar.open(alertMessage, "Dismiss", {
      panelClass: ["reset_password", success ? "success" : "failed"],
      duration: 5000,
    });
  }
}
