import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { StaticPageRoutingModule } from "../routes/static-page-routing.module";
import { StaticPageComponent } from "../static-page.component";

@NgModule({
  imports: [
    SharedModule,
    StaticPageRoutingModule,
  ],
  declarations: [
    StaticPageComponent,
  ],
})
export class SharedStaticPageModule {}
