import { Injectable } from "@angular/core";
import { Client, createClient, getRepositoryEndpoint } from "@prismicio/client";
import Showdown, { Converter } from "showdown";

import {
  CURRENT_PAGE,
  PageContent,
  PrismicPageSection,
  PrismicType,
} from "booking-app-v2/shared/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { from, map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrismicService {
  static readonly LANGUAGE_MAPPING = {
    "zh-TW": "zh-tw",
    "tw": "zh-tw",
    "ko": "ko-kr",
    "ja": "ja-jp",
    "zh-CN": "zh-cn",
    "id": "id",
    "vi": "vi",
  };

  private client: Client;
  private converter: Converter;

  constructor(
    private appSettingsService: AppSettingsService,
    private pageDiscoveryService: PageDiscoveryService,
  ) {
    this.converter = new Showdown.Converter();
  }

  initPrismicConnection(): void {
    this.client = createClient(getRepositoryEndpoint("ascendaloyalty"), { fetch });
  }

  getPage(locale: string): Observable<PageContent> {
    if (locale === "en") {
      locale = "en-us";
    } else {
      locale = PrismicService.LANGUAGE_MAPPING[locale];
    }

    return from(this.client.getByUID("pagecontent", this.getPath(), { lang: locale })).pipe(
      map((res: PrismicType) => this.processDocument(res)),
    );
  }

  getPath(): string {
    const { faqs, terms, privacyPolicy } = this.appSettingsService.prismicSettings;
    switch (this.pageDiscoveryService.currentPage()) {
      case CURRENT_PAGE.FAQ:
        return faqs;
      case CURRENT_PAGE.TERMS_CONDITION:
        return terms;
      case CURRENT_PAGE.PRIVACY_POLICY:
        return privacyPolicy;
    }
  }

  private processDocument(document: PrismicType): PageContent {
    const page: string[] = [];
    page.push(this.converter.makeHtml(document.data.title[0].text));
    page.push(this.buildPageSections(document.data.pagesections));
    return { page: { content: page.join("") } };
  }

  private buildPageSections(pageSections: PrismicPageSection[]): string {
    const pageContent: string[] = [];
    pageSections.forEach((section: any) => {
      if (section.title1.length > 0) {
        pageContent.push(this.converter.makeHtml(section.title1[0].text));
      }
      pageContent.push(this.converter.makeHtml(section.content[0].text));
    });

    return pageContent.join("");
  }
}
