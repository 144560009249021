import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { resetPasswordPageRoutes } from "./reset-password-page.routes";

@NgModule({
  imports: [
    RouterModule.forChild(resetPasswordPageRoutes),
  ],
  exports: [RouterModule],
})

export class ResetPasswordPageRoutingModule { }
